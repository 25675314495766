<template>
  <div name="register_edit">
    <innertopbar @close="closeWindow()">
      <template slot="breadcrumb">
        <router-link to="pupils"> Pupils </router-link>
        <i class="carret fas fa-caret-right"></i> {{ title }}
      </template>

        <template slot="listitems">
        <li
          v-if="!newAddition"
          @click="archive"
          title="Archive Pupil"
          class="action-list-item rounded-lg pt-2  inline-block hover:opacity-100"
        >
          <i class="fas fa-archive" title="Archive Pupil"></i>
          <p class="inline text-xs p-1">Archive</p>
        </li>
        <li
          v-if="!newAddition"
          @click="deletion"
          title="Delete Pupil"
          class="action-list-item rounded-lg pt-2  inline-block hover:opacity-100"
        >
          <i class="icon fas fa-trash-alt" title="Delete Pupil"></i>
          <p class="inline text-xs p-1">Delete</p>
        </li>

        <li
          v-if="!newAddition"
          class="inline-block mx-4 text-2xl text-gray-400"
        >
          |
        </li>

        <li class="inline-block">
          <button
            @click="submitForm()"
            type="button"
           class="publish-button hover:opacity-75"
          >
            Publish Changes
          </button>
        </li>
      </template>
    </innertopbar>

    <form v-on:submit.prevent="submitPupil" id="pupilForm" ref="form">
      <div class="w-full mx-auto grid grid grid-flow-col grid-cols-2 gap-20">
        <div class="mt-6 ml-10 p-8 bg-white rounded-lg mb-10">
          <div class="w-95p mx-auto">
            <h2 class="w-full text-xl mb-8 -mx-3 pt-4 font-bold text-gray-700">
              Pupil Information
            </h2>

            <div class="flex flex-wrap -mx-3 mb-8">
              <div class="w-full">
                <label class="form-label" for="grid-title"> Pupil Name </label>
                <input
                  v-model="name"
                  class="mb-2"
                  id="grid-title"
                  type="text"
                  placeholder="Enter pupil name"
                  required
                />
                <p class="text-red-300 text-xs italic">
                  Please fill out this field.
                </p>
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-8">
              <div class="w-full">
                <label class="form-label" for="grid-title"> Gender </label>
                <button
                  type="button"
                  class="
                    mr-3
                    mb-2
                    rounded
                    p-2
                    px-6
                    cursor-pointer
                                          hover:bg-gray-400 hover:bg-opacity-50 hover:text-action-blue

                    font-bold
                    icon
                    fa-male
                  "
                  v-bind:class="[
                    'male' == gender
                      ? 'bg-action-blue bg-opacity-75 text-white'
                      : 'bg-gray-200 text-gray-600',
                  ]"
                  @click="gender = 'male'"
                >
                  Male
                </button>

                <button
                  type="button"
                  class="
                    mr-3
                    mb-2
                    rounded
                    p-2
                    px-6
                    cursor-pointer
                                         hover:bg-gray-400 hover:bg-opacity-50 hover:text-action-blue

                    font-bold
                    icon
                    fa-female
                  "
                  v-bind:class="[
                    'female' == gender
                      ? 'bg-action-blue bg-opacity-75 text-white'
                      : 'bg-gray-200 text-gray-600',
                  ]"
                  @click="gender = 'female'"
                >
                  Female
                </button>
                <p class="text-red-300 text-xs mt-1 italic">
                  Please fill out this field.
                </p>
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-8">
              <div class="w-full">
                <label class="form-label" for="grid-ethnicity">
                  Ethnicity
                </label>
                <input
                  v-model="ethnicity"
                  class="mb-2"
                  id="grid-ethnicity"
                  type="text"
                  placeholder="Ethnicity"
                />
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-8">
              <div class="w-full">
                <label class="form-label" for="grid-sen"> SEN </label>
                <input
                  v-model="sen"
                  class="mb-2"
                  id="grid-sen"
                  type="text"
                  placeholder="SEN"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="p-6 px-8 bg-white h-screen">
          <div class="w-95p mx-auto">
            <savenotice
              :title="'Pupil'"
              ref="saveNotice"
              class="right"
            ></savenotice>

            <h2
              class="
                w-full
                text-xl
                mt-8
                mb-8
                -mx-3
                pt-4
                font-bold
                text-gray-700
              "
            >
              School Settings
            </h2>

            <div class="flex flex-wrap -mx-3 mb-8">
              <div class="w-full">
                <label class="form-label" for="grid-space"> Year Group </label>
                <div class="grid-cols-8">
                  <button
                    v-for="item in orderedYears"
                    :key="item.id"
                    type="button"
                    @click="yeargroup = item.value"
                    v-bind:class="
                      item.value == yeargroup
                        ? 'bg-action-blue bg-opacity-75 text-white'
                        : 'bg-gray-200 text-gray-600'
                    "
                    class="
                      mr-3
                      mb-2
                      rounded
                      p-2
                      px-6
                      cursor-pointer
                      hover:bg-gray-400 hover:bg-opacity-50 hover:text-action-blue
                    "
                  >
                    <i
                      class="fas"
                      v-bind:class="
                        yeargroup == item.value
                          ? 'fa-check-circle'
                          : 'fa-dot-circle'
                      "
                    ></i>
                    {{ item.name }}
                  </button>
                </div>
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 pr-3 mb-6 md:mb-0">
                <label class="form-label" for="grid-class"> Class </label>
                <dropdown
                  :options="classDropdown"
                  :selected="selectedClass"
                  v-on:updateOption="classSelect"
                  class="h-14 pl-4 bg-gray-100"
                ></dropdown>
              </div>

              <div class="w-full md:w-1/2 pl-3">
                <div class="w-full">
                  <label class="form-label" for="grid-class-other">
                    OR New Class
                  </label>
                  <input
                    v-model="classNew"
                    class="mb-2"
                    id="grid-sen"
                    type="text"
                    placeholder="New class..."
                  />
                </div>
              </div>

              <p class="text-red-300 mt-1 text-xs italic">
                Please fill out this field.
              </p>
            </div>

            <div class="flex flex-wrap -mx-3 mb-8">
              <div class="w-full">
                <label class="form-label" for="grid-title">
                  Pupil Premium
                </label>
                <button
                  type="button"
                  class="
                    mr-3
                    mb-2
                    rounded
                    p-2
                    px-6
                    cursor-pointer
                    hover:bg-gray-400 hover:bg-opacity-50 hover:text-action-blue
                  "
                  v-bind:class="[
                    hasPupilPremium
                      ? 'bg-action-blue bg-opacity-75 text-white'
                      : 'bg-gray-200 text-gray-600',
                  ]"
                  @click="hasPupilPremium = true"
                >
                  <i
                    class="fas"
                    v-bind:class="
                      hasPupilPremium ? 'fa-check-circle' : 'fa-dot-circle'
                    "
                  ></i>
                  Yes - On
                </button>

                <button
                  type="button"
                  class="
                    mr-3
                    mb-2
                    rounded
                    p-2
                    px-6
                    cursor-pointer
                    hover:bg-gray-400 hover:bg-opacity-50 hover:text-action-blue
                  "
                  v-bind:class="[
                    !hasPupilPremium
                      ? 'bg-action-blue bg-opacity-75 text-white'
                      : 'bg-gray-200 text-gray-600',
                  ]"
                  @click="hasPupilPremium = false"
                >
                  <i
                    class="fas"
                    v-bind:class="
                      !hasPupilPremium ? 'fa-check-circle' : 'fa-dot-circle'
                    "
                  ></i>
                  No - Off
                </button>
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-8">
              <div class="w-full">
                <label class="form-label" for="grid-title">
                  English as Second Language
                </label>
                <button
                  type="button"
                  class="
                    mr-3
                    mb-2
                    rounded
                    p-2
                    px-6
                    cursor-pointer
                    hover:bg-gray-400 hover:bg-opacity-50 hover:text-action-blue
                  "
                  v-bind:class="[
                    hasEAL
                      ? 'bg-action-blue bg-opacity-75 text-white'
                      : 'bg-gray-200 text-gray-600',
                  ]"
                  @click="hasEAL = true"
                >
                  <i
                    class="fas"
                    v-bind:class="hasEAL ? 'fa-check-circle' : 'fa-dot-circle'"
                  ></i>
                  Yes - On
                </button>

                <button
                  type="button"
                  class="
                    mr-3
                    mb-2
                    rounded
                    p-2
                    px-6
                    cursor-pointer
                    hover:bg-gray-400 hover:bg-opacity-50 hover:text-action-blue
                  "
                  v-bind:class="[
                    !hasEAL
                      ? 'bg-action-blue bg-opacity-75 text-white'
                      : 'bg-gray-200 text-gray-600',
                  ]"
                  @click="hasEAL = false"
                >
                  <i
                    class="fas"
                    v-bind:class="!hasEAL ? 'fa-check-circle' : 'fa-dot-circle'"
                  ></i>
                  No - Off
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { yearGroupMixin} from "@/mixins/yearGroupMixin.js";
import { SchoolService } from "@/services/school.service";

const innertopbar = () => import("@/components/InnerTopBar.vue");
const savenotice = () => import("@/components/SaveNotice.vue");
const dropdown = () => import("@/components/Dropdown.vue");

export default {
  name: "register_edit",
  mixins: [yearGroupMixin],
  props: {
    newAddition: Boolean,
    pupil: Object,
    classes: Array,
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
    }),
    // ...mapGetters({ currentUser: 'currentUser' }),
    isDisabled() {
      if (this.selectedClass.id > 0 && this.name.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    classDropdown() {
      var results = [{ id: 0, name: "Select a class" }].concat(this.classes);
      if (this.selectedClass.name === undefined) {
        this.selectedClass = results[0];
      }

      return results;
    },
    orderedYears() {
     var results = this.years
      return results;
    },
    componentLoaded() {
      return this.users.length > 0;
    },
    title() {
      if (this.newAddition) {
        return "Add new pupil";
      } else {
        return this.name;
      }
    },
    yearname() {
      if (this.newAddition) {
        return "";
      } else {
        return pupilYearGroupDisplay(this.pupil)
      }
    },
  },
  data() {
    return {
      school: this.$store.getters.school,
      errored: false,
      pupilInEdit: true,
      hasPupilPremium: false,
      hasEAL: false,
      selectedClass: {},
      selectedOrg: {},
      types: [],
      sen: "",
      ethnicity: "",
      name: "",
      gender: "male",
      yeargroup: -1,
      classNew: "",
    };
  },
  created() {
    if (this.newAddition == false) {
      if (this.pupil == undefined) {
        this.closeWindow();
        return;
      } else {
        this.hasPupilPremium =
          this.pupil.premium_pupil.Int64 === 0 || undefined ? false : true;
        this.hasEAL =
          this.pupil.english_second.Int64 === 0 || undefined ? false : true;
        this.sen =
          this.pupil.sen_status === undefined
            ? ""
            : this.pupil.sen_status.String;
        this.ethnicity =
          this.pupil.ethnicity == undefined ? "" : this.pupil.ethnicity.String;
        this.name = this.pupil.name == undefined ? "" : this.pupil.name;
        this.gender =
          this.pupil.gender == undefined
            ? "male"
            : this.pupil.gender.toLowerCase();
        this.yeargroup =
          this.pupil.yeargroup == undefined ? -1 : this.pupil.yeargroup;
        this.setClass();
      }
    }
  },
  mounted() {
  },
  methods: {
    submitForm() {
     if (this.$refs.form.requestSubmit === undefined) {
         this.submitPupil()
      } else {
        this.$refs.form.requestSubmit();
      }
    },
    alertDisplay() {
      this.$swal({
        title: "Please complete all fields",
        text: "You must enter a title and set a class to complete the form.",
        type: "warning",
        showCancelButton: false,
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result === true) {
          this.processPupil();
        }
      });
    },
    submitPupil() {
      if (
        this.name == "" ||
        (this.selectedClass.id === 0 && this.classNew.length === 0)
      ) {
        this.alertDisplay();
      } else {
        this.processPupil();
      }
    },
    processPupil() {
      // values
      this.pupil.name = this.name;
      this.pupil.gender = this.gender;
      this.pupil.yeargroup = this.yeargroup;

      if (this.selectedClass.id === 0) {
        this.pupil.class = this.classNew;
      } else {
        this.pupil.class = this.selectedClass.name;
      }

      let eal = this.hasEAL === true ? 1 : 0;
      this.pupil.english_second = {
        Int64: eal,
        Valid: true,
      };

      let premium = this.hasPupilPremium === true ? 1 : 0;
      this.pupil.premium_pupil = {
        Int64: premium,
        Valid: true,
      };

      this.pupil.ethnicity = {
        String: this.ethnicity,
        Valid: true,
      };
      this.pupil.updated_at = new Date();
      this.pupil.sen_status = {
        String: this.sen,
        Valid: true,
      };

      if (this.pupil.id === undefined) {
        SchoolService.create("pupils", this.pupil)
          .then((response) => this.completion(this.pupil.name + " added"))
          .catch((err) => {
            this.failure(err);
          });
      } else {
        SchoolService.update("pupils", this.pupil.identifier, this.pupil)
          .then((response) => this.completion(this.pupil.name + " updated"))
          .catch((err) => {
            this.failure(err);
          });
      }
    },
    setClass() {
      let t = this.classes
        .filter((tt) => {
          return tt.name == this.pupil.class;
        })
        .shift();
      this.selectedClass = t;
    },
    completion(msg) {
      this.$router.push({ name: "Pupils", params: { msg: msg } });
      window.scrollTo(0, 0);
    },
    failure(error) {
      this.$refs.saveNotice.failed();
      console.log("Pupil update error " + error);
    },
    classSelect(value) {
      this.selectedClass = value;
    },
    deletion() {
      this.$swal({
        title: "Are you sure you want to delete?",
        text: "You will not be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result.value === true) {
          this.processDelete();
        }
      });
    },
    processDelete() {
      SchoolService.delete("pupils", this.pupil.identifier, null)
        .then((response) =>
          this.completion(this.pupil.name + " deleted from the system")
        )
        .catch((err) => {
          console.log(err);
        });
    },
    archive() {
      this.$swal({
        title: "Are you sure you want to archive?",
        text: "All pupil data will still be shown in reporting",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, archive!",
      }).then((result) => {
        if (result.value === true) {
          this.processArchive();
        }
      });
    },
    processArchive() {
      let payload = this.pupil;
      payload.archived = 1;
      SchoolService.update("pupils", this.pupil.identifier, payload)
        .then((response) => this.completion(this.pupil.name + " archived"))
        .catch((err) => {
          this.failure(err);
        });
    },
    closeWindow() {
      this.$router.push({ path: "pupils" });
      window.scrollTo(0, 0);
    },
  },
  components: {
    dropdown,
    savenotice,
    innertopbar,
  },
};
</script>

<style scoped>
.row {
  margin-bottom: 11px;
}

input[type="radio"] {
  margin: 12px !important;
  width: 2em;
  height: 2em;
  cursor: pointer;
}
.right {
  float: right;
}

a.back {
  cursor: pointer !important;
}

.options section:hover {
  cursor: pointer;
}

.btn-important {
  background-color: #f4ab64 !important;
  background-image: none;
}

.buttons {
  padding-left: 0;
  margin-right: 14px;
}

.submit {
  box-shadow: none !important;
  width: 100%;
  height: 4em;
}

.view-middle.grey {
  background-color: #f6f7fb;
}

.view-middle .content.form {
  width: 100%;
}
</style>